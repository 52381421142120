import React, { useEffect, useState } from "react"
import styled from "styled-components";
import fase1 from "../../shared/img/faces/1.svg"
import "../../components/test.css"
import classnames from "classnames"

const getRandomInt = (min: number = 1, max: number = 3) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
};

interface IPops {
    img: string;
    cls: string;
}

export const WrapperImage: React.FC<IPops> = ({ img, cls }) => {
    const [time, setTime] = useState(1);
    useEffect(() => {
        const timeout = setInterval(() => {
            setTime(getRandomInt());
        }, 1500);
        return () => {
            clearInterval(timeout)
        }
    }, [time])
    return (
        <img className={classnames(time === 1 ? "example-enter example-enter-active" :
            "example-leave example-leave-active")} id={cls} src={img} alt={""} />
    )
}