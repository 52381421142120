export interface ITheme {
  boxShadow: string;
  fontFamily: string;
  maxWidth: number;
  mobileWidth: number;
  mainContentWidth: number;
  columnMaxWidth: number;
  colors: {
    black: string;
    blueBlack: string;
    white: string;
    violet: string;
    blue: string;
    turquoise: string;
    activeBlue: string;
    error: string;
    red: string;
    gray: string;
  };
  sizes: {
    mainMenuFoldingWidth: number;
  };
}

export const theme: ITheme = {
  boxShadow: "0 2px 6px 1px rgba(0, 0, 0, 0.03)",
  colors: {
    white: "#ffffff",
    black: "#060512",
    blueBlack: "#0B1249",
    violet: "#8799BB",
    turquoise: "#3455C5",
    blue: "#329EFB",
    activeBlue: "#99BBDD",
    error: "orange",
    red: "#F54C31",
    gray: "#F5F8FF",
  },
  fontFamily: "GilroyRegular",
  sizes: {
    mainMenuFoldingWidth: 1000,
  },
  maxWidth: 1465,
  mobileWidth: 420,
  mainContentWidth: 1182,
  columnMaxWidth: 360,
};
