import { Action, combineReducers } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { MailReducer } from "../../modules/mailReduser";
import { UIReducer } from "../../modules/ui/reducer";

export const mainReducer = combineReducers({
  mail: MailReducer,
  ui: UIReducer,
});

export interface IAppState extends ReturnType<typeof mainReducer> {}

export interface IAppDispatch extends ThunkDispatch<IAppState, Error, Action> {}

// export type SimpleThunk = (dispatch: IAppDispatch, getState: () => IAppState) => Promise<void>;
export interface IThunkAction extends ThunkAction<Promise<void>, IAppState, Error, Action> {}
