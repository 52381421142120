import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { Provider } from "react-redux";
import "./App.css";
import { Header } from "./components/heder";
import { Loader } from "./components/Loader";
import { SectionHello } from "./components/sectionHello/sectionHello";
import { Speakers } from "./components/sectionSpeaker/Speakers";
import { configureStore } from "./core/store/configureStore";
import { styled, ThemeProvider } from "./core/styledComponents";
import { theme } from "./core/theme";
import { SectionHow } from "./components/SectionHow";
import { SectionProgram } from "./components/SectionProgram";
import { Footer } from "./components/footer";
import { Development } from "./components/development/Development";
import { Fair } from "./components/Fair";

export const { store, persistor } = configureStore();

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CommonContainer>
          <Header />
          <TitlePart>
            <Loader />
            <SectionHello />
          </TitlePart>
          <Container>
            <Speakers />
            <Development />
            <SectionHow />
            <SectionProgram />
          </Container>
          <Footer name={""} request={""} loadState={false} error={null} />
        </CommonContainer>
      </ThemeProvider>
    </Provider>
  );
}

export default App;

const Container = styled.div`
  @media (min-width: 992px) {
    z-index: 100;
    margin-bottom: 740px;
  }
`;
const CommonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${theme.colors.turquoise};
`;

const TitlePart = styled.div`
  min-height: calc(100vh - 108px);
  z-index: 100;
  display: flex;
  flex-direction: column;
`;
