import React from "react";
import styled from "styled-components";
import { theme } from "../../core/theme";
import { SectionSpeaker } from "../SectionSpeaker";

import Microsoft from "../../shared/img/logos/logo-microsoft.svg";
import Yandex from "../../shared/img/logos/logo-yandex.svg";
import Mail from "../../shared/img/logos/logo-mail.svg";
import Postgres_logo from "../../shared/img/logos/logo-postgres.svg";
import Logo_65apps from "../../shared/img/logos/logo-65apps.svg";
import x5 from "../../shared/img/logos/logo-x5.svg"
import { GlobalPAddingContainer } from "../../modules/ui/GlobalStyles";

export const Speakers = () => {
  return (
    <Wrapper>
      <Section>
        <Container>
          <ImgContainer>
            <Img src={Microsoft} alt={""} />
          </ImgContainer>
          <ImgContainer>
            <Img src={Yandex} alt={""} />
          </ImgContainer>
          <ImgContainer>
            <Img src={Mail} alt={""} />
          </ImgContainer>
          <ImgContainer>
            <Img src={Postgres_logo} alt={""} />
          </ImgContainer>
          <ImgContainer>
            <Img src={Logo_65apps} alt={""} />
          </ImgContainer>
          <ImgContainer>
            <Img src={x5} alt={""} />
          </ImgContainer>
        </Container>
        <SectionSpeaker />
      </Section>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${theme.colors.white};
  display: flex;
  justify-content: center;

`;
const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Img = styled.img`
  width: 146px;
  height: auto;
  max-width: 100%;
  padding-bottom: 180px;
  display: flex;
  justify-content: center;
  mix-blend-mode: luminosity;
  &:hover {
    mix-blend-mode: normal;
  }
  @media screen and (max-width: 415px) {
    display: flex;
    justify-content: space-between;
    max-width: 133px;
  }
  @media screen and (max-width: 768px) {
    padding-bottom: 45px;
  }
  @media screen and (min-width: 768px) and (max-width: 1360px) {
    padding-bottom: 100px;
  }
`;

const Section = styled(GlobalPAddingContainer)`
  /* width: 100%; */
  background: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  @media (max-width: 768px) {
    padding: 25px;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;  
  }
  @media screen and (max-width: 1360px) {
    width: -webkit-fill-available; 
  }
  @media screen and (min-width: 1360px) {
    padding-bottom: 100px;
  }
  @media screen and (min-width: 1600px) {
    display: flex;
    justify-content: space-between;
    width: -webkit-fill-available;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  /* max-width: 278px; */
  max-width: 337px;

  @media screen and (max-width: 1360px) {
    max-width: 266px;
  }

  @media screen and (min-width: 768px) and (max-width: 1152px) {
    width: fit-content;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 460px) {
    grid-gap: 10px;
  }
`;
