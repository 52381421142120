import React from "react";
import { styled } from "../core/styledComponents";

export class Loader extends React.Component {
  public readonly state = { visible: true };

  componentDidMount(): void {
    setTimeout(() => {
      this.setState({ visible: false });
    }, 1500);
  }

  public render() {
    return (
      <Section visible={this.state.visible}>
        <Pulse />
      </Section>
    );
  }
}

const Section = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 999;
  background: white;
`;

const Pulse = styled.div`
  width: 4rem;
  height: 4rem;
  animation: scale 1s infinite cubic-bezier(0.6, 0.3, 0.3, 0.6);
  background: #1976d2;
  border-radius: 50%;

  @-webkit-keyframes scale {
    0% {
      -webkit-transform: scale(0);
    }

    50% {
      -webkit-transform: scale(1);
    }

    100% {
      -webkit-transform: scale(0);
    }
  }

  @keyframes scale {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }
`;
