import React from "react";
import { styled } from "../../../core/styledComponents";
import { theme } from "../../../core/theme";

export const PeopleInfo = () => {
  return (
    <PeopleContainer>
      <WrapperPeople>
        <CountPeople>1000+</CountPeople>
        <People>Участников</People>
      </WrapperPeople>
      <WrapperPeople>
        <CountPeople>20</CountPeople>
        <People>Спикеров</People>
      </WrapperPeople>
      <div>
        <CountPeople>3</CountPeople>
        <People>Потока</People>
      </div>
    </PeopleContainer>
  );
};

const WrapperPeople = styled.div`
  margin-right: 130px;
  @media screen and (max-width: 1360px) {
    margin-right: 95px;
  }
  @media screen and (min-width: 1360px) and (max-width: 1600px) {
    margin-right: 137px;
  }
  @media screen and (max-width: 1152px) {
    margin-right: 0px;
    margin-bottom: 44px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 0px;
  }
`;

const PeopleContainer = styled.div`
  display: flex;
  margin-bottom: 43px;
  @media screen and (max-width: 1600px) { 
    /* margin-bottom: 5px; */
  }
  @media screen and (max-width: 1152px) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }

`;
const CountPeople = styled.div`
  font-family: RubikMedium;
  font-weight: 500;
  font-size: 60px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: ${theme.colors.white};
  @media screen and (max-width: 1600px) {
    font-size: 48px;
  }
  @media screen and (min-width: 1360px) and (max-width: 1600px) {
    line-height: 1.1;
    font-size: 44px;
  }
  @media screen and (min-width: 768px) and (max-width: 1360px) {
    line-height: 120%;
  }
  @media screen and (max-width: 768px) {
    font-size: 34px;
  }
`;
const People = styled.div`
  font-family: RubikLight;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: ${theme.colors.white};
  @media screen and (max-width: 1600px) {
    font-size: 24px;
  }
  @media screen and (min-width: 768px) and (max-width: 1360px) {
    font-family: RubikLight;
    line-height: 120%;
    font-size: 30px;
    /* margin-top: 7px; */
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;
