import React, { FC } from "react";
import styled from "styled-components";

interface IOuterProps {
  backgroundColor?: string;
  outerStyle?: React.CSSProperties;
}

interface IInnerProps {
  innerStyle?: React.CSSProperties;
}

interface IProps extends IOuterProps, IInnerProps { }
export const ContentContainer: FC<IProps> = ({ children, backgroundColor, outerStyle, innerStyle }) => {
  return (
    <OuterContainer backgroundColor={backgroundColor} style={outerStyle}>
      <InnerContainer style={innerStyle}>{children}</InnerContainer>
    </OuterContainer>
  );
};

export const OuterContainer = styled.div<IOuterProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  background-color: ${(props) => props.backgroundColor || "transparent"};
`;

export const InnerContainer = styled.div<IInnerProps>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;

  @media screen and (min-width: 1601px) {
    max-width: 1465px;
  }
  @media screen and (min-width: 1360px) and (max-width: 1600px) {
    max-width: 1100px;
  }
  @media screen and (min-width: 768px) and (max-width: 1360px) {
    padding: 0 64px;
  }
  @media screen and (max-width: 768px) {
    padding: 0 25px;
  }
`;
