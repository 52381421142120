import React from "react";
import { styled } from "../core/styledComponents";
import { theme } from "../core/theme";
import { Cards } from "./sectionSpeaker/Cards";
import { arraySpeakers, timeArraySpeakers } from "./sectionSpeaker/info";

export class SectionSpeaker extends React.Component {
  public render() {
    return (
      <Section>
        <ContainerCards>
          {arraySpeakers.map((item, index) => (
            <Cards
              key={index}
              name={item.name}
              kindOfActivity={item.kindOfActivity}
              photo={item.photo}
              company={item.company}
              attr={item.attr}
              cls={item.cls}
            />
          ))}
        </ContainerCards>
        <Them>Спикеры и темы докладов уточняются</Them>
      </Section>
    );
  }
}
const Them = styled.div`

  font-family: RubikLight;
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: 0.02em;
  padding-bottom: 30px;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 28px;    
  }
  @media screen and (min-width: 768px) and (max-width: 1360px) {
    font-size: 36px;
  }
`

const Section = styled.div`
  background: ${theme.colors.white};
  margin-left: 16px;
  margin-right: 16px;
  padding-right: 64px;
  @media screen and (max-width: 1600px) {
    padding-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  @media screen and (min-width: 768px) and (max-width: 1360px) {
    margin: 0px;
  }
  @media screen and (max-width: 767px) {
    display: contents;
  }
  @media screen and (min-width: 1360px) {
    margin-right: 0px;
  }
 
  @media screen and (min-width: 1600px) {
    padding-right: 0px;
    padding-left: 31px;
  }
`;

const ContainerCards = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 265px);
  padding-bottom: 80px;

  @media screen and (min-width: 1222px) and (max-width: 1599px) {
    grid-template-columns: repeat(3, 266px);
  }
  @media screen and (min-width: 1600px) {
    grid-template-columns: repeat(3, 340px);    
    grid-gap: 60px 30px;
  }

  /* @media screen and (min-width: 1151px) and (max-width: 1230px) {
    grid-gap: 60px 0px;
  } */

  @media (min-width: 1231px) and (max-width: 1599px) {
    grid-gap: 60px 15px;
  }
  @media screen and (max-width: 460px) {
    grid-gap: 10px;
    padding-bottom: 32px;
  }
  @media screen and (max-width: 833px) {
    grid-template-columns: repeat(2, 220px);
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);    
  }
  @media screen and (max-width: 375px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* grid-template-columns: repeat(1, 285px);     */
  }
`;
