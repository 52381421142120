import { useCallback, useLayoutEffect, useState } from "react";

const events = ["scroll", "resize", "orientationChange"];

export const useWindowScroll = () => {
  const [scroll, setScroll] = useState({ x: 0, y: 0 });

  const handleScroll = useCallback(() => {
    setScroll({
      x: window.scrollX,
      y: window.scrollY,
    });
  }, []);

  useLayoutEffect(() => {
    handleScroll();
    events.forEach((event) => {
      document.addEventListener(event, handleScroll);
    });

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, handleScroll);
      });
    };
  }, [handleScroll]);

  return { scrollX: scroll.x, scrollY: scroll.y };
};
