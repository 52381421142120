import { reducerWithInitialState } from "typescript-fsa-reducers";
import { MailAction } from "./mailAction";
interface ILoading {
  error: string | null;
  loadState: boolean;
  request: string;
  name: string;
}

const MailState: ILoading = {
  loadState: false,
  error: null,
  request: "",
  name: "",
};

export const MailReducer = reducerWithInitialState(MailState)
  .case(MailAction.sendMail.started, state => ({ ...state, error: null, loadState: true }))
  .case(MailAction.sendMail.done, (state) => ({
    ...state,
    error: null,
    loadState: false
  }))
  .case(MailAction.sendMail.failed, (state, { error }) => ({
    ...state,
    error: error.message,
    loadState: false
  }))

  .case(MailAction.getName, (state, payload) => ({
    request: state.request,
    name: payload,
    error: null,
    loadState: false
  }));
