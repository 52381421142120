import { IThunkAction } from "../core/store/mainReducer";
import { MailAction } from "./mailAction";
import { IRequest } from "./Request";

export class MailActionAsync {
  public static SendMail(name: string, surname: string, email: string, work: string): IThunkAction {
    return async (dispatch) => {
      var values = {
        name,
        surname,
        email,
        work,
      };

      const newValues: IRequest = {
        name: values.name + " " + values.surname,
        message: "DDay? Место работы:" + values.work,
        phone: values.email,
      };

      var body = new FormData();
      Object.keys(newValues).forEach(function (name) {
        body.append(name, (newValues as any)[name]);
      });

      // const headers = {Accept: "application/json", "Access-Control-Allow-Origin": "https://stage.day.dex-it.ru"};
      dispatch(MailAction.sendMail.started(newValues));
      try {
        // https://dex-it.ru/Ru/Page
        const result = await fetch("https://dex-it.ru/Ru/Page/TechTaskEmail", {
          // headers,
          method: "POST",
          body: body,
        })
        if (result.ok) {
          dispatch(MailAction.getName(values.name));
          dispatch(MailAction.sendMail.done({ params: newValues }));
        } else {
          dispatch(MailAction.sendMail.failed({
            error: { message: "Something went wrong", name: "Error" },
            params: newValues
          }))
        }
      } catch (error) {
        dispatch(MailAction.sendMail.failed(error))
      }
    }
  }
}
