import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink } from "../../ui/NavLink";

interface ILinkBlockProps {
  open: string;
  mobile?: boolean;
  onClick?: () => void;
}

export const LinkBlock: React.FC<ILinkBlockProps> = ({ open, mobile, onClick }) => {
  const [path, setPath] = useState();
  useEffect(() => {
    setPath(window.location.hash)
  }, [window.location.hash])
  return (
    <NavContainer className={open} mobile={mobile}>
      <NavLink href="#spicker" onClick={onClick} activeStyle={path === "#spicker"}>
        СПИКЕРЫ
      </NavLink>
      <NavLink href="#forWhom" onClick={onClick} activeStyle={path === "#forWhom"}>
        ДЛЯ КОГО
      </NavLink>
      <NavLink href="#program" onClick={onClick} activeStyle={path === "#program"}>
        ПРОГРАММА
      </NavLink>
      <NavLink href="#registration" onClick={onClick} activeStyle={path === "#registration"}>
        РЕГИСТРАЦИЯ
      </NavLink>
    </NavContainer>
  );
}

const NavContainer = styled.div<{ mobile?: boolean }>`
  display: flex;
  justify-content: space-between;
  z-index: 999;
  @media (max-width: 850px) {
    padding-top: 20px;
    flex-direction: column;
    display: none;
  }
  &.open {
    @media (max-width: 850px) {
      display: none;
    }
  }
  & > :not(:first-child) {
    margin-left: 73px;
    @media screen and (max-width: 1360px) {
      margin-left: 24px;
    }
  }
  ${({ mobile }) =>
    mobile &&
    `flex-direction: column;
    @media (max-width: 850px) {
      padding-top: 20px;
      display: flex;
      > a {
        margin-left: 24px;
      }
    }
  `}
`;
