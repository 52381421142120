import React, { FC } from "react";
import styled from "styled-components";

interface IProps {
  onClick?: () => void;
  fill?: string;
}

export const CloseIcon: FC<IProps> = ({ onClick, fill = "#fff" }) => {
  return (
    <SVGContainer onClick={onClick}>
      <SVG viewBox="0 0 14 14">
        <g id="01-Главная" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="01.05.02_320" transform="translate(-21.000000, -21.000000)">
            <g id="ic_close_24px" transform="translate(16.000000, 16.000000)">
              <polygon
                id="Path"
                fill={fill}
                fillRule="nonzero"
                points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"
              />
              <polygon id="Path" points="0 0 24 0 24 24 0 24" />
            </g>
          </g>
        </g>
      </SVG>
    </SVGContainer>
  );
};

export const SVGContainer = styled.div`
  max-width: 100%;
  cursor: pointer;
`;

export const SVG = styled.svg`
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  height: 100%;
`;
