import * as React from "react";

export const Check: React.FC = () => (
  <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.5 0C14.58 0 0 14.5788 0 32.5C0 50.4212 14.58 65 32.5 65C50.42 65 65 50.4212 65 32.5C65 14.5788 50.42 0 32.5 0ZM32.5 62.5C15.9587 62.5 2.5 49.0412 2.5 32.5C2.5 15.9587 15.9587 2.5 32.5 2.5C49.0412 2.5 62.5 15.9587 62.5 32.5C62.5 49.0412 49.0412 62.5 32.5 62.5Z"
      fill="white"
    />
    <path
      d="M47.8152 19.1701L28.6039 40.7826L17.0302 31.5239C16.4927 31.0926 15.7039 31.1814 15.2739 31.7189C14.8427 32.2589 14.9302 33.0451 15.4689 33.4764L27.9689 43.4764C28.1989 43.6601 28.4752 43.7501 28.7502 43.7501C29.0952 43.7501 29.4389 43.6076 29.6852 43.3301L49.6852 20.8301C50.1439 20.3151 50.0977 19.5239 49.5814 19.0664C49.0639 18.6064 48.2752 18.6526 47.8152 19.1701Z"
      fill="white"
    />
  </svg>
);
