import borodkin from "../../shared/img/speakers/alex-borodkin.png";
import kuzin from "../../shared/img/speakers/artur-kuzin.png";
import zhelnin from "../../shared/img/speakers/dmitry-zhelnin.png";
import kolodyazch from "../../shared/img/speakers/pavel-kolodyazch.png";
import korotkiy from "../../shared/img/speakers/pavel-korotkiy.png";
import dubeshko from "../../shared/img/speakers/vika-dubeshko.png";
import babushkin from "../../shared/img/speakers/valerii-babushkin.png";
import bogdanov from "../../shared/img/speakers/alex-bogdanov.png";
import voronin from "../../shared/img/speakers/kim-koronin.png";
import akentieva from "../../shared/img/speakers/anna-akentieva.svg";
import karpov from "../../shared/img/speakers/andrei-karpov.png";

import alphabank from "../../shared/img/logos/logo-alphabank.svg"
import rosbank from "../../shared/img/logos/logo-rosbank.svg"
import rambler from "../../shared/img/logos/logo-rambler.svg"
import x5 from "../../shared/img/logos/logo-x5.svg"
import apps from "../../shared/img/logos/logo-65apps.svg"
import globus from "../../shared/img/logos/logo-globus.svg"
import yandex from "../../shared/img/logos/logo-yandex.svg"
import agima from "../../shared/img/logos/logo-agima.svg"
import vtb from "../../shared/img/logos/logo-vtb.svg"
import postgres from "../../shared/img/logos/logo-postgres.svg"
import classmates from "../../shared/img/logos/logo-classmates.svg"

import timeDev from "../../shared/icons/timeDev.svg"
import timeDes from "../../shared/icons/timeDes.svg"
import timeMen from "../../shared/icons/timeMen.svg"

import headphone from "../../shared/img/headphones.svg";
import glasses from "../../shared/img/glasses.svg";
import micro from "../../shared/img/micro.svg";
import mouse from "../../shared/img/mouse.svg";
import mobile from "../../shared/img/mobile.svg";
import headphonesmicro from "../../shared/img/headphonesmicro.svg";
import headphonesred from "../../shared/img/headphonesred.svg";
import garniture from "../../shared/img/garniture.svg";
import badge from "../../shared/img/badge.svg";
import classmateAttr from "../../shared/img/classmates.svg"

export type IImageTypes =
  | "MOUSE"
  | "HEADPHONES"
  | "HEADPHONESRED"
  | "BADGE"
  | "MOBILE"
  | "GLASSES"
  | "GARNITURE"
  | "MICRO"
  | "HEADPHONESMICRO"
  | "CLASSMATEATTR"
  | "";

export interface IData {
  photo: string;
  name: string;
  kindOfActivity: string;
  company: string;
  attr: string | null;
  cls: IImageTypes;
}

export const arraySpeakers: IData[] = [
  {
    photo: babushkin,
    name: "Валерий Бабушкин",
    kindOfActivity: "Специалист по Data Science",
    company: yandex,
    attr: mouse,
    cls: "MOUSE",
  },
  {
    photo: dubeshko,
    name: "Виктория Дубешко",
    kindOfActivity: "Senior Project Manager",
    company: rambler,
    attr: headphonesred,
    cls: "HEADPHONESRED",
  },
  {
    photo: kuzin,
    name: "Артур Кузин",
    kindOfActivity: "Руководитель направления нейронных сетей",
    company: x5,
    attr: headphone,
    cls: "HEADPHONES",
  },
  {
    photo: bogdanov,
    name: "Александр Богданов",
    kindOfActivity: "Генеральный директор",
    company: agima,
    attr: badge,
    cls: "BADGE",
  },
  {
    photo: zhelnin,
    name: "Дмитрий Желнин",
    kindOfActivity: "CEO",
    company: apps,
    attr: mobile,
    cls: "MOBILE",
  },
  {
    photo: kolodyazch,
    name: "Павел Колодяжный",
    kindOfActivity: "Руководитель направления продуктового дизайна",
    company: alphabank,
    attr: glasses,
    cls: "GLASSES",
  },
  {
    photo: korotkiy,
    name: "Павел Короткий",
    kindOfActivity: "Генеральный директор",
    company: globus,
    attr: garniture,
    cls: "GARNITURE",
  },
  {
    photo: borodkin,
    name: "Алексей Бородкин",
    kindOfActivity: "Product lead",
    company: rosbank,
    attr: micro,
    cls: "MICRO",
  },
  {
    photo: voronin,
    name: "Ким Воронин",
    kindOfActivity: "Руководитель группы визуальных коммуникаций",
    company: vtb,
    attr: headphonesmicro,
    cls: "HEADPHONESMICRO",
  },
  {
    photo: akentieva,
    name: "Анна Акентьева",
    kindOfActivity: "Программист",
    company: postgres,
    attr: null,
    cls: "",
  },
  {
    photo: karpov,
    name: "Андрей Карпов",
    kindOfActivity: "Менеджер по развитию бизнеса",
    company: classmates,
    attr: classmateAttr,
    cls: "CLASSMATEATTR",
  },
];


export const timeArraySpeakers = [
  {
    photo: timeDev,
    name: "",
    theme: "Разработка",
    kindOfActivity: "",

    timeout: false,
  },
  {
    photo: timeDes,
    name: "",
    theme: "Дизайн",
    kindOfActivity: "",

    timeout: false,
  }, {
    photo: timeMen,
    name: "",
    theme: "Менеджмент",
    kindOfActivity: "",

    timeout: false,
  }
]