import React from "react";
import { styled } from "../core/styledComponents";
import { theme } from "../core/theme";
import { TimeBlock } from "./SectionProgram/TimeBlock";
import { Directions } from "./Directions";
import { Fair } from "./Fair";
import { GlobalTitle, GlobalPAddingContainer } from "../modules/ui/GlobalStyles";

export const SectionProgram = () => {
  return (
    <Section id="program">
      <Container>
        <GlobalTitle>Программа конференции</GlobalTitle>
        {/* <DescriptionSection>
          3 параллельных потока по разработке, дизайну и менеджменту
        </DescriptionSection> */}
        <ContainerCards>
          <TimeBlock time={"10:00"} description={"Начало регистрации"} last={false} />
          <TimeBlock time={"10:30"} description={"Церемония открытия D-day"} last={false} />
          <TimeBlock time={"10:40"} description={`Начало докладов, мастер-классов, круглых столов и ярмарки ИТ.\nПрограмма каждой секции уточняется.`} last={true} />
          {/* <TimeBlock size={"48px"} time={"Программа и спикеры уточняются"} description={``} last={true} /> */}
          <Directions />
          <Fair />
        </ContainerCards>
      </Container>
    </Section>
  );
}

const Container = styled(GlobalPAddingContainer)`
  @media (max-width: 767px) {
    padding: 25px;
  }
  @media screen and (min-width: 768px) and (max-width: 1360px) {
    padding: 50px 64px;
  }

  @media screen and (min-width: 1360px) and (max-width: 1599px) {
    padding: 100px 0px;
    max-width: 1100px;
  }
  @media screen and (min-width: 1600px) {
    padding: 100px 0px;
    max-width: 1465px;
  }
`
const Section = styled.div`
  display: flex;
  justify-content: center;
  background: ${theme.colors.white};
  box-shadow: 0 45px 45px rgba(1, 1, 1, 0.3);
`;

const DescriptionSection = styled.div`
  font-family: RubikLight;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  padding-top: 20px;
  letter-spacing: 0.01em;
  max-width: 828px;

  color: ${theme.colors.black};
  @media (max-width: 767px) {
    font-size: 24px;
  }
  @media (max-width: 420px) {
    font-size: 16px;
  }
`;

const ContainerCards = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  @media screen and (max-width: 784px) {
    padding-top: 40px;
  }
`;