import React from "react"
import styled from "styled-components";

export const RegistrationLoader = () => {
    return (
        <Conatiner>
            <Load className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </Load>
        </Conatiner>
    )
}

const Conatiner = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 25%;
    height: 100%;
    width: 100%;
    @media screen and (min-width: 1600px) {
        margin-top: 15%;        
    }
    @media screen and (min-width: 1152px) and (max-width: 1599px) {
        margin-top: 20%;        
    }
`
const Load = styled.div`
    width: 5rem;
    height: 5rem;
`