import styled from "styled-components";
import { theme } from "../../core/theme";

export const GlobalTitle = styled.div`
  font-family: RubikMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: ${theme.colors.blueBlack};
  @media (max-width: 768px) {
    font-size: 34px;
  }
`;

export const GlobalDescription = styled.div`
  font-family: RubikLight;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 140%;
  letter-spacing: 0.01em;
  @media (max-width: 768px) {
    font-size: 24px;
  }
  @media (max-width: 573px) {
    font-size: 16px;
  }
`;

export const GlobalPAddingContainer = styled.div`
  padding-top: 100px;
  @media screen and (min-width: 1360px) and (max-width: 1600px) {
    max-width: 1100px;
    /* padding: 50px 64px; */
  }
  @media screen and (min-width: 1600px) {
    max-width: 1465px;
  }
  @media screen and (min-width: 768px) and (max-width: 1360px) {
    padding: 50px 64px;
  }
  @media (max-width: 768px) {
    padding: 25px;
  }
`;
