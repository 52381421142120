import React, { SyntheticEvent } from "react";
import styled from "styled-components";
import { theme } from "../../core/theme";
import { MailDispatch, MailState } from "./selectors";
import { connect } from "react-redux";

interface IProps extends ReturnType<typeof MailState>, ReturnType<typeof MailDispatch> { }

interface IState {
  name: string;
  surname: string;
  email: string;
  work: string;
  errorInput: boolean;
}

class FormInputConstructor extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { name: "", email: "", surname: "", work: "", errorInput: false };
  }

  public render() {
    return (
      <>
        <Container>
          <InputContainer
            className={"col-lg-6 col-sm-12"}
            error={this.state.errorInput && this.state.name.length === 0}>
            <Input
              name={"name"}
              type={"text"}
              placeholder={"Ваше имя*"}
              onChange={this.changeName}
              error={this.state.errorInput && this.state.name.length === 0}
            />
          </InputContainer>
          <InputContainer
            className={"col-lg-6 col-sm-12"}
            error={this.state.errorInput && this.state.surname.length === 0}>
            <Input
              name={"surname"}
              type={"text"}
              placeholder={"Ваша фамилия*"}
              onChange={this.changeSurname}
              error={this.state.errorInput && this.state.surname.length === 0}
            />
          </InputContainer>
          <InputContainer
            className={"col-lg-6 col-sm-12"}
            error={this.state.errorInput && this.state.email.length === 0}>
            <Input
              name={"e-mail"}
              type={"text"}
              placeholder={"Email или телефон*"}
              onChange={this.changeEmail}
              error={this.state.errorInput && this.state.email.length === 0}
            />
          </InputContainer>
          <InputContainer
            className={"col-lg-6 col-sm-12"}
            error={this.state.errorInput && this.state.work.length === 0}>
            <Input
              name={"work"}
              type={"text"}
              placeholder={"Место работы или учебы*"}
              onChange={this.changeWork}
              error={this.state.errorInput && this.state.work.length === 0}
            />
          </InputContainer>
          {/* <ButtonSubmit onClick={this.Submit}>Зарегистрироваться</ButtonSubmit> */}
        </Container>
        {this.props.request === "fail" && (
          <ErrorMessage> При регистрации произошла ошибка. Повторите попытку позже</ErrorMessage>
        )}
      </>
    );
  }

  private changeName = (event: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ name: event.currentTarget.value });
  };

  private changeSurname = (event: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ surname: event.currentTarget.value });
  };

  private changeEmail = (event: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ email: event.currentTarget.value });
  };

  private changeWork = (event: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ work: event.currentTarget.value });
  };
  private Submit = () => {
    const { name, surname, work, email } = this.state;
    if (name !== "" && surname !== "" && email !== "" && work !== "") {
      this.props.sendMail(name, surname, email, work);
    } else {
      this.setState({ errorInput: true });
    }
  };
}

export const FormInput = connect(MailState, MailDispatch)(FormInputConstructor);

const Container = styled.div`
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1072px;
  @media (max-width: 991px) {
    flex-direction: column;
  }
  @media (min-width: 1599px) {
  justify-content: space-between;
    max-width: 1072px;
  }
`;

const InputContainer = styled.div<{ error?: boolean }>`
  padding-left: 0;
  padding-right: 30px;
  margin-top: 40px;
  max-width: 459px;
  /* border-bottom: 1px solid ${(props) => (props.error ? theme.colors.error : 'rgba(255, 255, 255, 0.7)')}; */
  @media (max-width: 768px) {
    padding-right: 0;
  }
  @media (max-width: 991px) {
    max-width: 100%;
  }
  &:before {
    content: " ";
    width: 93%;
    height: 1px;
    background: ${(props) => (props.error ? theme.colors.error : theme.colors.white)};
    opacity: 0.7;
    position: absolute;
    bottom: 0;
    @media (max-width: 991px) {
       width: 100%;
    }
  }
  &:nth-child(2n):before{
    width: 100%;
  }
`;

const Input = styled.input<{ error?: boolean }>`
  font-family: RubikLight;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: ${theme.colors.white};
  width: 100%;

  background: inherit;
  border: none;
  padding: 8px;
  outline: none !important;

  @media (max-width: 1110px) and (min-width: 992px){
    font-size: 23px;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(255, 255, 255, 0.7);
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${(props) => (props.error ? theme.colors.error : theme.colors.white)};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => (props.error ? theme.colors.error : theme.colors.white)};
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 100px ${theme.colors.turquoise} inset;
    -webkit-text-fill-color: ${theme.colors.white};
  }
`;

const ButtonSubmit = styled.div`
  user-select: none;
  background: ${theme.colors.red};
  color: #fff;
  padding: 18px 30px;
  font-family: RubikMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 50px;
  /* margin-right: 30px; */
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-left: auto;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: ${theme.colors.white};
    img {
      opacity: 0.4;
    }
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #F54C31;
  }

  @media (max-width: 768px) {
    margin-left: auto;
  }
  @media (max-width: 460px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 420px) {
    font-size: 16px;
    padding: 18px 40px;
    width: 100%;
    text-align: center;
  }
`;

const ErrorMessage = styled.div`
  font-size: 18px;
  font-family: GilroyMedium, sans-serif;
  color: ${theme.colors.error};
  margin-top: 16px;
`;
