import React from "react";
import styled from "styled-components";
import { theme } from "../../core/theme";
import { ImageMouse, ImageHeadPhoneRed, Border, ImageHeadPhones, ImageBadge, ImageMobile, ImageGlasses, ImageGarniture, ImageMicro, ImageHeadPhoneMicro, ImageClassmates } from "./AnimationStyle";
import { IImageTypes, IData } from "./info";

export const Cards: React.FC<IData> = ({ photo, name, kindOfActivity, company, attr, cls }) => {
  return (
    <Container className={cls}>
      <Border>
        <Image alt={"photo"} src={photo} />
        {attr && cls === "MOUSE" && <ImageMouse className={cls} src={attr} alt={"img"} />}
        {attr && cls === "HEADPHONESRED" && <ImageHeadPhoneRed className={cls} src={attr} alt={"img"} />}
        {attr && cls === "HEADPHONES" && <ImageHeadPhones className={cls} src={attr} alt={"img"} />}
        {attr && cls === "BADGE" && <ImageBadge className={cls} src={attr} alt={"img"} />}
        {attr && cls === "MOBILE" && <ImageMobile className={cls} src={attr} alt={"img"} />}
        {attr && cls === "GLASSES" && <ImageGlasses className={cls} src={attr} alt={"img"} />}
        {attr && cls === "GARNITURE" && <ImageGarniture className={cls} src={attr} alt={"img"} />}
        {attr && cls === "MICRO" && <ImageMicro className={cls} src={attr} alt={"img"} />}
        {attr && cls === "HEADPHONESMICRO" && <ImageHeadPhoneMicro className={cls} src={attr} alt={"img"} />}
        {attr && cls === "CLASSMATEATTR" && <ImageClassmates className={cls} src={attr} alt={"img"} />}
        <WrapperText>
          <Title>{name}</Title>
          <Description>{kindOfActivity}</Description>
        </WrapperText>
        <ThemeContainer>
          <Img src={company} alt={""} />
        </ThemeContainer>
      </Border>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  /* height: 100%;
  width: 100%; */
  width: -webkit-fill-available;

  @media screen and (max-width: 375px) {
    /* max-width: 134px; */
    max-height: 337px;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 768px) and (max-width: 1360px) {
    display: flex;
    max-width: 263px;
    height: 330px;
  }

  @media screen and (min-width: 1600px) {
    width: -webkit-fill-available;
    max-width: 340px;
    height: 446px; 
  }
`;
const ThemeContainer = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 460px) {
    margin-top: auto; 
  }
`;

const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
`

const Img = styled.img`
  max-width: 100%;
  height: auto;
  @media screen and (max-width: 1600px) {
    max-height: 30px;
  }
  @media screen and (max-width: 768px) {
    max-height: 25px;
  }
  @media screen and (max-width: 460px) {
    max-height: 18px;
  }
  @media screen and (max-width: 375px) {
    max-width: 94px;
    max-height: 34px;
  }
`



const Image = styled.img`
  /* max-width: -webkit-fill-available; */
  margin: 0px auto;
  display: block;
  /* max-width: 146px; */
  max-height: 165px;
  max-width: 100%;
  height: auto;
  @media screen and (max-width: 375px) {
    /* max-height: 106px;
    max-width: 118px; */
  }
  
  @media screen and (min-width: 1360px) and (max-width: 1600px) {
    max-width: 231px;
    max-height: 204px;
  }
  @media screen and (min-width: 1600px) {
    height: -webkit-fill-available;
    max-height: 270px;
    max-width: 305px;
  }
`;

const Title = styled.div`
  font-family: RubikMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: ${theme.colors.black};
  text-align: center;
  padding-top: 8px;
  @media screen and (max-width: 1600px) {
    font-size: 21px;
  }
  @media screen and (max-width: 768px) {
    padding-top: 17px;
  }
  @media screen and (max-width: 460px) {
    font-size: 16px;
    padding-top: 8px;
  }
  @media screen and (max-width: 375px) {
    font-size: 21px;
  }
`;

const Description = styled.div`
  font-family: RubikLight;
  font-style: normal;
  font-weight: 300;
  text-align: center;
  font-size: 17px;
  line-height: 1.2;
  letter-spacing: 0.01em;
  padding-top: 5px;
  padding-bottom: 12px;
  @media screen and (max-width: 1360px) {
    font-size: 14px;
  }
  @media screen and (max-width: 460px) {
    font-size: 12px;
  }
  @media screen and (max-width: 375px) {
    font-size: 14px;
  }
`;