import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useWindowScroll } from "../core/hooks/useWindowScroll";
import { IAppState } from "../core/store/mainReducer";
import { Burger } from "../shared/icons/Burger";
import { LogoDexTagline } from "../shared/icons/LogoDexTagline";
import { ContentContainer } from "./common/ContentContainer";
import { ExpandedMenu } from "./common/ExpandedMenu";
import { CloseIcon } from "./common/icons/CloseIcon";
import { LinkBlock } from "./heder/NavLink";
import { RegistrationButton } from "./sectionHello/components/RegistrationButton";
import { theme } from "../core/theme";

export const Header = () => {
  const regButtonIsVisible = useSelector((state: IAppState) => state.ui.regButtonIsVisible);

  const [open, setOpen] = useState("");
  const { scrollY } = useWindowScroll();
  const openMenu = () => {
    if (!open) {
      setOpen("open");
    }
  };
  const closeMenu = () => {
    setOpen("");
  };

  const onClickLogo = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Sticky shadow={scrollY > 0}>
        <ContentContainer backgroundColor={`${theme.colors.turquoise}`}>
          <HeaderSection>
            <NavContainer>
              <LeftBlock onClick={onClickLogo}>
                <LogoDexTagline />
              </LeftBlock>
              <EmptyStub />
              {/* {!regButtonIsVisible && <RegistrationButton forHeader={true} />} */}
              <MenuWrapper onClick={closeMenu} className={open} />
              <LinkBlock onClick={closeMenu} open={open} />
              <StyledBurger onClick={openMenu}>
                <Burger />
              </StyledBurger>
            </NavContainer>
          </HeaderSection>
        </ContentContainer>
      </Sticky>
      <ExpandedMenu isOpen={open === "open"} closeMenu={closeMenu}>
        <Menu>
          <ContentContainer backgroundColor={`${theme.colors.turquoise}`}>
            <MenuSection>
              <NavContainer>
                <LeftBlock onClick={onClickLogo}>
                  <LogoDexTagline />
                </LeftBlock>
                <StyledBurger onClick={openMenu}>
                  <CloseIconContainer>
                    <CloseIcon onClick={closeMenu} />
                  </CloseIconContainer>
                </StyledBurger>
              </NavContainer>
            </MenuSection>
          </ContentContainer>
        </Menu>
        <LinkBlock onClick={closeMenu} open={""} mobile />
      </ExpandedMenu>
    </>
  );
};

const Sticky = styled.div<{ shadow?: boolean }>`
  position: sticky;
  top: 0;
  z-index: 999;
  transition: all 300ms ease-in-out;
  ${(props) => (props.shadow ? `box-shadow: 0px 4px 18px rgba(11, 47, 102, 0.37);` : "")}
`;

const Menu = styled.div`
  position: sticky;
  top: 0;
`;

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 19px 0;
  z-index: 999;
  transition: all 0.2s ease-in-out;
  @media (max-width: 850px) {
    justify-content: space-between;
    padding: 22px 0;
  }
`;

const EmptyStub = styled.div`
  height: 60px;
  @media screen and (max-width: 850px) {
    display: none;
  }
`;

const MenuSection = styled(HeaderSection)``;

const CloseIconContainer = styled.div`
  width: 20px;
`;

const NavContainer = styled.nav`
  max-width: 1500px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  position: relative;
  @media (max-width: 850px) {
    max-width: 100%;
    flex-direction: column;
    margin: 0;
  }
`;

const StyledBurger = styled.div`
  display: none;
  position: absolute;
  right: 5px;
  @media (max-width: 850px) {
    display: block;
  }
  cursor: pointer;
`;

const MenuWrapper = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 990;
  &.open {
    display: block;
  }
`;

const LeftBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
