import React from "react";
import { styled } from "../core/styledComponents";
import { theme } from "../core/theme";
import "./test.css";
import { Info } from "./Info";
import { GlobalTitle, GlobalPAddingContainer } from "../modules/ui/GlobalStyles";

export class SectionHow extends React.Component {
  public render() {
    return (
      <Section>
        <Container>
          <LeftContainer>
            <div>
              <TitleSection>Как это было?<br /> D-Day 2019</TitleSection>
              <SubText>D-day  - победитель народного голосования в рейтинге Tagline!</SubText>
            </div>
            <DescriptionSection>
              <Info title={"700+"} description={"Участников"}></Info>
              <Info title={"16"} description={"Спикеров"}></Info>
              <Info title={"20"} description={"Докладов"}></Info>
            </DescriptionSection>
          </LeftContainer>
          <GridVideo>
            <iframe
              width="100%"
              height="100%"
              className="embed-responsive-item col-video"
              title="descriptor"
              src="//www.youtube.com/embed/tQwcxjFoVGM"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </GridVideo>
          <MobileDescription>
            <Info title={"700+"} description={"Участников"}></Info>
            <Info title={"16"} description={"Спикеров"}></Info>
            <Info title={"20"} description={"Докладов"}></Info>
          </MobileDescription>
        </Container>
      </Section>
    );
  }
}
const MobileDescription = styled.div`
  display: none;
  @media screen and (max-width: 1090px) {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }  
`

const SubText = styled.div`
  font-family: RubikLight;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: ${theme.colors.white};
  padding-top: 16px;
  max-width: 468px;
 
  @media (max-width: 420px){
    font-size: 16px;
  }
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
  @media screen and (max-width: 1090px) {
    max-width: 100vw;
    padding-bottom: 15px;
  }
`

const DescriptionSection = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1090px) {
    display: none;
  }
`;

const GridVideo = styled.div`
  /* display: contents; */
  display: flex;

  /* max-width: 828px; */
  height: 178px;
  @media screen and (min-width: 768px) {
    height: 428px
  }
  @media screen and (min-width: 1024px) {
    height: 598px
  }
  @media screen and (min-width: 1090px) and (max-width: 1360px){
    height: 467px
  }
  @media screen and (min-width: 1360px)  and (max-width: 1600px){
    height: 438px
  } 
  @media screen and (min-width: 1600px) {
    height: 523px
  }
  iframe {
    border-radius: 16px;
    padding: 0;
  }
`;

const Container = styled(GlobalPAddingContainer)`
  height: 100%;
  display: grid;
  grid-gap: 150px;
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: 522px;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 100px;

  @media screen and (max-width: 384px) {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 20px 0px;
    /* height: 485px;     */
  }

  @media screen and (min-width: 385px) and (max-width: 768px) {
    padding: 24px;
    display: flex;
    flex-direction: column;
    /* height: 522px;     */
  }

  @media screen and (min-width: 768px) and (max-width: 1090px){
    padding: 50px 64px;
    display: flex;
    flex-direction: column;
    /* max-height: 800px; */
  }
  @media screen and (min-width: 1090px) and (max-width: 1360px){
    padding: 50px 64px;
    grid-gap: 10px;
    grid-template-rows: 468px;
  }
  @media screen and (min-width: 1360px) and (max-width: 1599px) {
    max-width: 1100px;
    grid-gap: 30px;
    grid-template-rows: 439px;
  } 
  @media screen and (min-width: 1600px) {
    max-width: 1465px;
    grid-gap: 30px;
  }
`;

const Section = styled.div`
  display: flex;
  justify-content: center;
  background: ${theme.colors.turquoise};
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TitleSection = styled(GlobalTitle)`
  color: ${theme.colors.white};
  @media (min-width: 768px) and (max-width: 1050px) {
    font-size: 54px;
  }
  @media (min-width: 1051px) and (max-width: 1200px) {
    font-size: 48px;
  }
  @media screen and (min-width: 1201px){
    font-size: 48px;
  }
`;
