import React, { CSSProperties } from "react";
import styled from "styled-components";
import point from "../../shared/icons/point.svg";
import { theme } from "../../core/theme";
import { GlobalTitle } from "../../modules/ui/GlobalStyles";

interface IProps {
  time: string;
  description: string;
  last: boolean;
  size?: string;
}

export class TimeBlock extends React.Component<IProps> {
  public render() {
    const { size } = this.props;
    return (
      <Section>
        <Border>
          <div>
            <Img src={point} alt={"point"} />
            {this.props.last ? null : <Line />}
          </div>
          <Container>
            <Time style={{ fontSize: size }}>{this.props.time}</Time>
            {!this.props.size && <Hyphen>—</Hyphen>}
            <Description>{this.props.description}</Description>
          </Container>
        </Border>
      </Section>
    );
  }
}

const Section = styled.div`
  border-radius: 15px;
  align-self: flex-start;
`;

const Border = styled.div`
  border-top: none;
  border-right: none;
  border-bottom: none;
  display: grid;
  grid-gap: 0px 20px;
  
  grid-template-columns: 50px 1fr;
  grid-template-rows: 1fr;
  justify-content: center;
  @media screen and (max-width: 459px) {
    grid-gap: 0px 10px;
    grid-template-columns: 32px 1fr;
  }
  @media screen and (min-width: 785px) and (max-width: 950px) { 
    grid-template-columns: 50px 1fr;
  }
  @media screen and (max-width: 784px) {
    grid-template-columns: 50px 1fr;
  }
`;

const Container = styled.div`
  display: flex;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`

const Line = styled.div`
  margin-left: 22px;
  height: 92px;
  width: 1px;
  background: #B4C5FF;
  @media screen and (max-width: 459px) {
    margin-left: 17px;
    height: 44px;
  }

  @media screen and (min-width: 460px) and (max-width: 784px) {
    height: 64px;
  }
`
const Hyphen = styled.span`
  padding: 23px 16px 0px 14px;
  @media screen and (min-width: 785px) and (max-width: 959px) { 
    padding-top: 26px;
  }
  @media screen and (max-width: 800px) { 
    display: none;
  }
`
const Img = styled.img`
  padding-top: 15px;
  padding-bottom: 15px;
  @media screen and (max-width: 459px) {
    width: 32px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
`

const Time = styled(GlobalTitle)`
  /* font-size: 60px; */
  position: relative;
  @media screen and (max-width: 459px) {
    padding-top: 8px;
    font-size: 26px;    
  }
  @media screen and (min-width: 460px) and (max-width: 784px) {
    font-size: 36px;
    padding-top: 17px;
  }
  @media screen and (min-width: 785px) and (max-width: 959px) {
    padding-top: 10px;
    font-size: 48px;
  }
`;

const Description = styled.div`
  font-family: RubikLight;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  color: ${theme.colors.black};
  position: relative;
  padding-top: 15px;
  white-space: pre-wrap;
  @media screen and (max-width: 459px) {
    padding-top: 8px;
    font-size: 16px;    
  }
  @media screen and (min-width: 460px) and (max-width: 784px) {
    font-size: 24px;
  }
  @media screen and (min-width: 785px) and (max-width: 959px) { 
    padding-top: 20px;
    font-size: 24px;
  }
`;