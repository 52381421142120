import React from "react"
import styled from "styled-components";
import { theme } from "../core/theme";

interface IProps {
  title: string;
  description: string;
}

export const Info: React.FC<IProps> = ({ title, description }) => {
  return (
    <Wrapper>
      <Count>{title}</Count>
      <Inf>{description}</Inf>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media screen and (min-width: 1600px) {
    padding-right: 70px;
  }
  @media screen and (max-width: 1599px) {
    padding-right: 20px;      
  }

`
const Count = styled.div`
  font-family: RubikMedium;
  font-weight: 500;
  font-size: 60px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: ${theme.colors.white};
  @media screen and (max-width: 1600px) {
    font-size: 48px;
  }
  @media screen and (min-width: 1360px) and (max-width: 1600px) {
    line-height: 1.1;
    font-size: 44px;
  }
  @media screen and (min-width: 768px) and (max-width: 1360px) {
    line-height: 120%;
  }
  @media screen and (max-width: 768px) {
    font-size: 34px;
  }
`;
const Inf = styled.div`
  font-family: RubikLight;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: ${theme.colors.white};
  @media screen and (max-width: 1600px) {
    font-size: 24px;
  }
  @media screen and (min-width: 768px) and (max-width: 1360px) {
    font-family: RubikLight;
    line-height: 120%;
    font-size: 24px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;
