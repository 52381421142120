import React, { FC, useLayoutEffect, useRef, useState, useEffect } from "react";
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { createPortal } from "react-dom";
import styled from "styled-components";

const useShift = (open?: boolean) => {
  const [shift, setShift] = useState(false);

  if (open && !shift) {
    setShift(true);
  } else if (!open && shift) {
    setTimeout(() => {
      setShift(false);
    }, 200);
  }

  return shift;
};

interface IPropsPortal {
  id?: string;
}

const EmptyPortal: FC<IPropsPortal> = ({ children, id = "footer-info" }) => {
  const [render, setRender] = useState(false);

  useLayoutEffect(() => {
    setRender(true);
  }, []);

  return render ? createPortal(<>{children}</>, document.getElementById(id) as HTMLDivElement) : null;
};

interface IProps {
  isOpen: boolean;
  closeMenu: () => void;
  level?: number;
  noBackground?: boolean;
}

export const ExpandedMenu: FC<IProps> = ({ closeMenu, isOpen, children, level, noBackground }) => {
  const shift = useShift(isOpen);
  const scroll = useRef<any>(null);
  const backgroundMenu = useRef<any>(null);

  document.ontouchmove = (event: TouchEvent) => {
    if (event.target === backgroundMenu.current) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    scroll.current && (isOpen ? disableBodyScroll(scroll.current) : enableBodyScroll(scroll.current));
  }, [isOpen]);

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const zIndex = !level ? 100 : level + 100;

  return (
    <EmptyPortal id={"root"}>
      <Expand open={isOpen} right={true} style={{ zIndex: zIndex + 1000 }} ref={scroll}>
        {children}
      </Expand>
      <BackgroundMenu
        ref={backgroundMenu}
        open={isOpen}
        onClick={closeMenu}
        shift={shift}
        style={{ zIndex }}
        noBackground={noBackground}
      />
    </EmptyPortal>
  );
};

const Expand = styled.div<{ open: boolean; right?: boolean }>`
  background-color: #3455c5;

  will-change: transform;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;

  justify-content: space-between;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.5);
  transition: transform 0.2s ease-in-out;
  @media only screen and (max-width: 600px) {
    width: 100%;
    transform: translate3d(-120%, 0, 0);
  }
  @media only screen and (min-width: 601px) {
    width: 360px;
    transform: translate3d(-380px, 0, 0);
  }

  ${({ open }) =>
    open &&
    `
    transform: translate3d(0, 0, 0) !important;
  }
  `}
  left: auto;
  right: 0;
  @media only screen and (max-width: 600px) {
    transform: translate3d(120%, 0, 0);
  }
  @media only screen and (min-width: 601px) {
    transform: translate3d(380px, 0, 0);
  }
`;

interface IBackgroundMenuProps {
  open: boolean;
  shift: boolean;
  noBackground?: boolean;
}

const BackgroundMenu = styled.div<IBackgroundMenuProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  left: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.2s ease-in-out;
  -webkit-overflow-scrolling: touch;
  @media only screen and (max-width: 600px) {
    display: none;
  }
  &.open {
    background-color: rgba(0, 0, 0, 0.75);
    /* rgba(255, 255, 255, 0.75); */
  }
  &.open.noBackground {
    background-color: transparent;
  }
  &.shift {
    right: 0;
    left: 0;
  }
`;
