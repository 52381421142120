import React from "react";
import { styled } from "../../core/styledComponents";
import { GlobalTitle } from "../../modules/ui/GlobalStyles";

interface IProps {
    img: string;
    alt: string;
    description: string;
}

export const Card: React.FC<IProps> = ({ img, alt, description }) => {
    return (
        <Wrapper>
            <Img src={img} alt={alt} />
            <Text> {description} </Text>
        </Wrapper>
    )
}

const Text = styled(GlobalTitle)`
    font-size: 28px;
    letter-spacing: 0.03em;
    padding-top: 40px;
    @media screen and (max-width: 1200px) {
        font-size: 24px;
    }
    @media screen and (max-width: 573px) {
        font-size: 18px;
    }
`

const Img = styled.img`
    max-width: 100%;
    height: auto;
    align-self: center;
    padding-top: 40px;
    @media screen and (min-width: 1360px) and (max-width: 1600px) {
        width: 73px;
    }
    @media screen and (max-width: 573px) {
        width: 90px;
        height: 90px;
    }
`

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;