import { styled } from "../core/styledComponents";
import { theme } from "../core/theme";

export const NavLink = styled.a<{ activeStyle?: boolean }>`
  font-family: RubikRegular;
  font-size: 18px;
  line-height: 140%;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-decoration: none;
  padding: 8px 0 3px 0;
  color: ${(props) => (props.activeStyle ? theme.colors.white : theme.colors.white)};
  border-bottom: ${(props) => (props.activeStyle ? "#ffffff 1px solid" : "transparent 1px solid")};
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none;
    border: none;
    color: #ffffff;
    border-bottom: #ffffff 1px solid;
  }
  &:active {
    text-decoration: none;
    color: #ffffff;
    border-bottom: #ffffff 1px solid;
  }
  @media (max-width: 768px) {
    border-bottom: none;
    text-align: center;
    padding: 8px 0;
    font-size: 16px;
    align-self: flex-start;
  }
`;
