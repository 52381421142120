import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import styled from "styled-components";
import { theme } from "../../core/theme";
import { UIAction } from "../../modules/ui/action";
import illustration from "../../shared/img/illustration.svg";
import illustrationFree from "../../shared/img/illustrationFree.svg";
import { ContentContainer } from "../common/ContentContainer";
import { HelloDate } from "./components/HelloDate";
import { PeopleInfo } from "./components/PeopleInfo";
import { RegistrationButton } from "./components/RegistrationButton";
import facebook from "../../shared/icons/f.svg"
import vk from "../../shared/icons/v.svg"
import telegram from "../../shared/icons/t.svg"
import insta from "../../shared/icons/i.svg"

import fase1 from "../../shared/img/faces/1.svg"
import fase2 from "../../shared/img/faces/2.svg"
import fase3 from "../../shared/img/faces/3.svg"
import fase4 from "../../shared/img/faces/4.svg"
import fase5 from "../../shared/img/faces/5.svg"
import { Img1, Img2, Img3, Img4, Img5 } from "./Animation";
import { WrapperImage } from "./WrapperImage";

import virus from "../../shared/img/virus.svg"

export const SectionHello = () => {
  const dispatch = useDispatch();
  const [img1, setImg1] = useState(1);
  const onChangeButtonVisibility = useCallback(
    (isVisible: boolean) => {
      dispatch(UIAction.setRegButtonVisibility(isVisible));
    },
    [dispatch],
  );

  return (
    <ContentContainer
      backgroundColor="#3455C5"
      outerStyle={{ minHeight: "calc(100vh - 98px)" }}
      innerStyle={{ flex: 1 }}>
      <CommonContainer>
        <Virus src={virus} alt="virus" />
        <Container>
          <LeftWrapper>
            <LeftContainer>
              {/* <HelloDate /> */}
              <Description>
                Самая<br /> масштабная<br /> IT-конференция
              </Description>
              {/* <GeopositionDescription>Тирасполь, Дворец Республики</GeopositionDescription> */}
              <VisibilitySensor onChange={onChangeButtonVisibility}>
                {/* <RegistrationButton /> */}
              </VisibilitySensor>
              <div id="spicker" />
            </LeftContainer>
            {/* <Speaker>Спикеры</Speaker> */}
            <Desktop>
              <PeopleInfo />
            </Desktop>
          </LeftWrapper>

          <RightContainer>
            <ContainerIllustration>
              <Illustration src={illustrationFree} alt={"illustration"} />
              <WrapperImage cls={"Img1"} img={fase1} />
              <WrapperImage cls={"Img2"} img={fase2} />
              <WrapperImage cls={"Img3"} img={fase3} />
              <WrapperImage cls={"Img4"} img={fase4} />
              <WrapperImage cls={"Img5"} img={fase5} />
            </ContainerIllustration>
            <Mobile>
              <PeopleInfo />
            </Mobile>
          </RightContainer>
        </Container>
      </CommonContainer>
    </ContentContainer>
  );
};

const Virus = styled.img`
  position: absolute;
  width: 100vw;
  bottom: 0;
  left: 0;
  z-index: 500;

  @media (max-width: 1600px) {
    top: 256px;
  }
  @media (max-width: 1360px) {
    top: 256px;
  }
  @media (max-width: 1152px) {

  }
  @media (max-width: 834px) {
    top: 200px;
  }
  @media (max-width: 768px) {
    bottom: 83px;
    top: unset;
  }
`

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  position: relative;
  @media (max-width: 1199px) {
    padding-bottom: 0px;
  }
  @media (max-width: 1152px) {
    padding-bottom: 20px;
    flex-direction: column;
  }
  @media (max-width: 768px) {
    padding-bottom: 0px;
  }
`;

const RightContainer = styled(Container)`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  max-width: 782px;
  @media screen and (max-width: 1600px) {
    align-items: flex-end;
  }
  @media screen and (max-width: 1152px) {
    display: flex;
    max-width: 100%;
    align-items: flex-end;
    flex-direction: row-reverse;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
    width: 100%;
  }
`
const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
`;
const LeftContainer = styled.div`
  /* margin-top: auto; */
  @media screen and (max-width: 1360px) {
    /* margin: auto 0; */
  }
`
const Speaker = styled.div`
  margin-top: auto;
  align-self: flex-start;
  font-family: RubikMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: ${theme.colors.black};
  padding: 46px 67px 45px 68px;
  /* padding: 22px 69px 23px 68px; */
  border-radius: 15px 15px 0 0;
  background: ${theme.colors.white};
  @media screen and (min-width: 768px) and (max-width: 870px) {
    padding: 16px 20px;    
    font-size: 38px;
  }
  @media screen and (min-width: 871px) and (max-width: 1600px) {
    font-size: 40px;
    padding: 16px 40px;
  }
  @media (max-width: 1152px) {
    display: none;
  }
`;

const CommonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top: 30px;
`;

const Description = styled.div`
  margin-top: 60px;
  font-family: RubikMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: ${theme.colors.white};
  @media screen and (max-width: 1600px) {
    font-size: 48px;
  }
  @media screen and (max-width: 800px) {
    font-size: 44px;
    margin-top: 30px;
  }
  @media (max-width: 768px) {
    font-size: 34px;
    margin-top: 22px;
  }
`;

const GeopositionDescription = styled.div`
  margin-top: 30px;
  font-family: RubikLight;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: ${theme.colors.white};
  @media screen and (max-width: 1600px) {
    font-size: 24px;
    margin-top: 24px;
  }
  @media screen and (max-width: 1360px) {
    font-size: 30px;
    margin-top: 20px;
  }
  @media screen and (max-width: 800px) {
    margin-top: 18px;
    font-size: 25px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    margin-top: 8px;
  }
`;

const ContainerIllustration = styled.div`
  position: relative;
`

const Illustration = styled.img`
  @media screen and (min-width: 1600px) {
    margin: auto 0; 
  }
  @media screen and (min-width: 1152px) and (max-width: 1600px) {
    margin: auto 0; 
    align-self: flex-end;
    max-height: 497px;
  }

  @media screen and (max-width: 1152px) {
    align-self: flex-end;
    height: 398px;
    max-width: 446px
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    align-self: center;
  }
`;

const Desktop = styled.div`
  @media screen and (max-width: 1152px) {
    display: none;
  }
  @media screen and (max-width: 768px) {
    padding-top: 46px;
    width: 100%;
  }
`;

const Mobile = styled.div`
  @media screen and (max-width: 768px) {
    padding-top: 46px;
    width: 100%;
  }
  @media screen and (min-width: 1152px) {
    display: none;
  }
`;
