import React from "react";
import { styled } from "../core/styledComponents";
import { theme } from "../core/theme";
import { SocialNetwork } from "./footer/SocialNetwork";
import { MailState } from "./footer/selectors";
import { connect } from "react-redux";
import { Check } from "../shared/icons/Check";
import { FormInput } from "./footer/FormInput";
import Dday from "../shared/icons/dday.svg";
import Footerico1 from "../shared/icons/footerico1.svg";
import Footerico2 from "../shared/icons/footerico2.svg";
import Footerico3 from "../shared/icons/footerico3.svg";
import Footerico4 from "../shared/icons/footerico4.svg";
import { LogoDexTagline } from "../shared/icons/LogoDexTagline";
import { Loader } from "./Loader";
import { RegistrationLoader } from "./loader/RegistrationLoader";
import logoFooter from "../shared/icons/logoFooter.svg"
import { GlobalTitle, GlobalDescription } from "../modules/ui/GlobalStyles";
import virus from "../shared/img/virus1.svg"

interface IProps extends ReturnType<typeof MailState> { }

class FooterContainer extends React.Component<IProps> {
  public render() {
    return (
      <Container id="registration">
        <Virus src={virus} alt="virus" />
        <FooterSection>
          {this.props.loadState &&
            <RegistrationLoader />
          }
          {!this.props.loadState && this.props.name === "" &&
            <>
              <Block>
                <Title>Вход и участие бесплатное!</Title>
                <SubTitle>Регистрация обязательна, количество мест ограничено</SubTitle>
                <FormInput />
                {/* <SocialNetwork /> */}
              </Block>
            </>
          }
          {!this.props.loadState && this.props.name !== "" &&
            <>
              <Block>
                <div>
                  <Title>Спасибо за регистрацию</Title>
                  <Description>{this.props.name}, мы ждем вас 22 марта на нашей конференции!</Description>
                  <BlockRegisterSuccessful>
                    <Check /> <span>Регистрация прошла успешно</span>
                  </BlockRegisterSuccessful>
                </div>
                {/* <SocialNetwork /> */}
              </Block>
            </>
          }
          <BlockLogo>
            <Logo src={logoFooter} alt={"logo"} />
            <Img src={Footerico1} alt={"1"} />
            <Img src={Footerico2} alt={"2"} />
            <Img src={Footerico3} alt={"3"} />
            <Img src={Footerico4} alt={"4"} />
            <WrapContact>
              <Email href={"mailto:office@dex-it.ru"}>
                office@dex-it.ru
                </Email>
              <Contact>
                +373-777-78335
                </Contact>
            </WrapContact>
            <LogoDexTagline />
          </BlockLogo>
        </FooterSection>
      </Container>
    );
  }
}

export const Footer = connect(
  MailState,
  undefined,
)(FooterContainer);

const Virus = styled.img`
  position: fixed;
  width: 100vw;
  bottom: 32px;
  left: 0;
  z-index: 99;
  @media screen and (max-width: 1600px) {
    bottom: 70px;
  }
  @media screen and (max-width: 991px) {
    bottom: 185px;
    position: absolute;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
`
const Img = styled.img`
  @media screen and (max-width: 1200px) {
    display: none;
  }
`
const Logo = styled.img`
  @media screen and (max-width: 640px) {
    display: none;
  }
`
const WrapContact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  @media screen and (max-width: 776px) {
    padding-top: 20px;
  }
  @media screen and (max-width: 992px) {
    flex: 0;
  }
`
const Contact = styled.div`
  font-family: RubikMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.07em;
  color: ${theme.colors.white};
  white-space: nowrap;
  @media screen and (max-width: 1600px) {
    font-size: 18px
  }
`
const Email = styled.a`
  font-family: RubikMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.07em;
  text-decoration-line: underline;
  color: ${theme.colors.white};
  white-space: nowrap;
  &:hover{
    color: ${theme.colors.white};
  }
  @media screen and (max-width: 1600px) {
    font-size: 18px
  }
  @media screen and (max-width: 776px) {
    text-align: center;
  }
`
const FooterSection = styled.div`
  background: ${theme.colors.turquoise};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  padding: 100px 0px 26px 0px;
  width: -webkit-fill-available;
  @media (min-width: 992px) {
    position: fixed;
    bottom: 0;
    z-index: 1;
    height: 740px;
  }
  @media (max-width: 768px) {
    padding: 25px;
  }
  @media screen and (min-width: 768px) and (max-width: 1360px) {
    padding: 50px 64px;
  }

  @media screen and (min-width: 1360px) and (max-width: 1599px) {
    padding: 50px 0px;
    max-width: 1100px;
  }
  @media screen and (min-width: 1600px) {
    max-width: 1465px;
  }
`;

const Title = styled(GlobalTitle)`
  font-size: 60px;
  color: ${theme.colors.white};
`;
const SubTitle = styled(GlobalDescription)`
  color: ${theme.colors.white};
`

const Description = styled(GlobalDescription)`
  font-size: 24px;
  line-height: 1.2;
  color: ${theme.colors.white};
  margin-top: 16px;
  margin-bottom: 20px;
`;

const BlockLogo = styled.div`
  display: flex;
  margin-top: auto;
  align-items: flex-end;
  max-width: 100%;
  height: auto;
  justify-content: space-between;
  @media screen and (max-width: 459px) {
    flex-direction: column-reverse; 
    align-items: center;
    margin-top: 32px;
  }
  @media screen and (min-width: 460px) and (max-width: 768px) {
    margin-top: 50px;
    align-items: center;
  }
`;

const Block = styled.div`
  flex-direction: column;
  display: flex;
  @media screen and (min-width: 550px) and (max-width: 991px) {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 550px) {
  }
`;

const BlockRegisterSuccessful = styled.div`
  display: flex;
  align-items: center;
  margin: 100px 0;
  @media (max-width: 768px) {
    margin: 50px 0;
  }

  span {
    font-family: RubikMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 120%;
    letter-spacing: 0.03em;
    color: ${theme.colors.white};
    padding: 0 40px;

    @media (max-width: 768px) {
      font-size: 24px;
    }
    @media (max-width: 420px) {
      font-size: 18px;
    }
  }
`;