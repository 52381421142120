import * as React from "react";
import styled from "styled-components";

export const LogoDexTagline: React.FC = () => (
  <Svg viewBox="0 0 191 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M105.07 8.38608H98.748V12.2415H99.4603C100.641 8.91182 102.207 9.161 102.207 9.161H103.992V20.7711C103.992 22.1785 101.677 21.9649 101.677 21.9649V22.6933H108.462V21.9649C108.462 21.9649 106.151 22.184 106.151 20.7765V9.161H107.933C107.933 9.161 109.501 8.91182 110.682 12.2415H111.394V8.38608H105.07Z"
        fill="white"
      />
      <path
        d="M123.813 20.322L119.102 8.16428H118.306L113.451 20.5219C113.255 20.9539 112.937 21.3204 112.534 21.5766C112.132 21.8329 111.662 21.9678 111.184 21.9649V22.6769H116.574L116.557 21.9649C114.434 21.9649 114.507 21.1435 114.507 21.1435V20.7355C114.507 20.333 115.501 17.6823 115.501 17.6823H120.623L121.823 20.8477C122.028 21.3516 121.953 21.5213 121.953 21.5213C121.726 22.069 120.102 21.9677 120.102 21.9677V22.6878H126.066V22.0334C124.359 22.0334 123.813 20.322 123.813 20.322ZM115.828 16.839L118.059 11.2475L120.304 16.839H115.828Z"
        fill="white"
      />
      <path
        d="M138.77 13.3505V8.37515H137.847L137.457 9.02411C136.32 8.38106 135.034 8.03958 133.723 8.03287C133.723 8.03287 129.067 7.79191 127.13 12.3182C126.425 13.9843 126.281 15.8306 126.72 17.5838C127.294 19.9058 128.915 23 133.482 23C135.316 23.0003 137.125 22.5843 138.77 21.7842V18.1534C138.77 18.1534 138.682 16.9732 140.644 16.9732V16.2585H134.33V16.9622C134.33 16.9622 136.714 16.9622 136.714 18.1506V21.0011C136.714 21.0011 136.456 22.1621 133.915 22.1621C133.915 22.1621 128.857 22.414 128.857 15.1358C128.857 15.1358 128.771 8.88172 133.771 8.88172C133.771 8.88172 136.958 8.69826 137.975 13.3505H138.77Z"
        fill="white"
      />
      <path
        d="M141.646 8.3642H147.769V9.02411C147.769 9.02411 145.77 9.0953 145.77 10.1413V20.6451C145.77 20.6451 145.673 21.902 147.156 21.902H149.373C149.373 21.902 150.482 21.9321 151.019 21.2475C151.019 21.2475 152.128 19.7936 152.555 18.419H153.278L152.948 22.6824H141.646V21.9787C141.646 21.9787 143.57 22.0663 143.57 20.9682V10.1714C143.57 10.1714 143.697 9.07614 141.646 9.07614V8.3642Z"
        fill="white"
      />
      <path
        d="M154.348 8.3642V9.0953C154.348 9.0953 156.551 9.16102 156.551 10.2098V21.1052C156.551 21.1052 156.687 21.9704 154.334 21.9704V22.6824H160.913V21.965C160.913 21.965 158.721 21.965 158.721 20.9682V10.1906C158.721 10.1906 158.544 9.0953 160.913 9.0953V8.3642H154.348Z"
        fill="white"
      />
      <path
        d="M162 8.3642V9.0953C162 9.0953 164.591 9.2815 164.591 11.3352V20.0975C164.591 20.0975 164.641 21.9896 162.2 21.9896V22.7043H168.191V21.965C168.191 21.965 165.597 22.0471 165.597 19.7744V11.1216L174.372 22.822H175.297V10.8396C175.297 10.8396 175.225 9.07066 177.628 9.08435V8.3642H171.714V9.0953C171.714 9.0953 174.233 9.1665 174.233 10.8779V19.2267L166.124 8.3642H162Z"
        fill="white"
      />
      <path
        d="M178.697 8.3642V9.0953C178.697 9.0953 180.754 9.06792 180.754 10.3138V21.1298C180.754 21.1298 180.809 21.9759 178.697 21.9759V22.7015H190.725L191.002 18.4902H190.265C190.265 18.4902 189.586 20.5356 188.699 21.3708C188.699 21.3708 188.145 21.8965 186.831 21.8965H183.988C183.988 21.8965 182.879 21.9431 182.879 20.511V15.667H184.966C184.966 15.667 186.928 15.6314 186.928 18.0904H187.735V12.5071H186.939C186.939 12.5071 186.892 14.8675 184.936 14.8675H182.879V9.13638H186.352C186.352 9.13638 188.223 9.0022 189.146 11.7815H189.93V8.3642H178.697Z"
        fill="white"
      />
      <path
        d="M87.468 8L82 18.2245L83.7987 22.9589L89.8514 22.6851L95.8571 13.5805L87.468 8ZM85.6666 21.4885C85.369 21.4885 85.078 21.4013 84.8305 21.2379C84.583 21.0745 84.3902 20.8423 84.2763 20.5706C84.1624 20.299 84.1326 20 84.1906 19.7116C84.2487 19.4232 84.392 19.1582 84.6025 18.9503C84.8129 18.7423 85.0811 18.6007 85.373 18.5434C85.6649 18.486 85.9675 18.5154 86.2425 18.628C86.5175 18.7405 86.7525 18.9311 86.9179 19.1756C87.0832 19.4201 87.1715 19.7076 87.1715 20.0016C87.1715 20.396 87.0129 20.7742 86.7307 21.053C86.4485 21.3318 86.0657 21.4885 85.6666 21.4885V21.4885Z"
        fill="#E1001A"
      />
    </g>
    <rect x="69.1084" y="9.90402" width="1.28572" height="9.00001" fill="white" />
    <rect x="74.25" y="13.7612" width="1.28572" height="9.00001" transform="rotate(90 74.25 13.7612)" fill="white" />
    <path
      d="M38.4429 17.1431H26.3329C26.9011 18.7115 28.266 19.4956 30.4266 19.4956C31.8137 19.4956 32.9047 19.0707 33.7014 18.2182L37.7951 20.5378C36.1341 22.8466 33.655 24 30.358 24C27.5153 24 25.2186 23.1594 23.4678 21.4788C21.7624 19.7984 20.9092 17.6811 20.9092 15.1262C20.9092 12.6169 21.7503 10.5106 23.4336 8.80731C25.1389 7.10402 27.3218 6.25238 29.9832 6.25238C32.4613 6.25238 34.5202 7.10402 36.1571 8.80731C37.8172 10.4659 38.6472 12.5713 38.6472 15.1262C38.6474 15.8438 38.5788 16.5158 38.4429 17.1431ZM26.231 13.3782H33.5644C33.0647 11.6084 31.8591 10.723 29.949 10.723C27.9707 10.723 26.7307 11.6084 26.231 13.3782Z"
      fill="white"
    />
    <path d="M48.71 11.1597H54.5762L57.7497 6.72284H51.8821L48.71 11.1597Z" fill="#FC336F" />
    <path
      d="M57.5411 23.5295H51.6734L48.7075 19.364L45.7417 23.5295H39.874L45.7742 15.2626L45.7724 15.2602L39.666 6.72284H45.5337L48.7058 11.1597L48.7075 11.1621L48.7093 11.1597L51.6426 15.2602L51.6408 15.2626L57.5411 23.5295Z"
      fill="white"
    />
    <path
      d="M18.011 15.1261C18.011 15.1258 18.0109 15.1253 18.0109 15.1249V0H12.8937V7.52997C12.0629 6.7113 10.4045 6.25228 9.00551 6.25228C4.0319 6.25228 0 10.2252 0 15.1261C0 20.027 4.0319 23.9999 9.00551 23.9999C10.6079 23.9999 11.8358 23.6 12.8937 22.72V23.5294H18.0109V15.1272C18.0109 15.1269 18.011 15.1264 18.011 15.1261ZM9.00551 18.8609C6.91218 18.8609 5.21534 17.1887 5.21534 15.1261C5.21534 13.0635 6.91218 11.3914 9.00551 11.3914C11.0987 11.3914 12.7957 13.0635 12.7957 15.1261C12.7957 17.1887 11.0987 18.8609 9.00551 18.8609Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0">
        <rect width="109" height="15" fill="white" transform="translate(82 8)" />
      </clipPath>
    </defs>
  </Svg>
);

const Svg = styled.svg`
  width: 206px;
  height: 26px;
`;
