import React from "react"
import { styled } from "../core/styledComponents";
import design from "../shared/img/design.svg"
import develop from "../shared/img/develop.svg"
import manage from "../shared/img/manage.svg"
import { theme } from "../core/theme";

export const Directions = () => {
    return (
        <ContainerIcons>
            <Img src={develop} alt={"develop"} />
            <Img src={design} alt={"design"} />
            <Img src={manage} alt={"manage"} />
        </ContainerIcons>
    )
}
const Img = styled.img`
    max-height: auto;
    max-width: 100%;
    @media screen and (max-width: 576px){
        padding-bottom: 20px;
    }
`

const ContainerIcons = styled.div`
  padding-top: 139px;

  background: ${theme.colors.white};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-end;
  justify-content: space-evenly;

  @media screen and (max-width: 576px){
      display: flex;
      flex-direction: column;
      align-self: center;
      max-width: 288px;
      padding-top: 50px;
  }
`;