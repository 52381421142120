import React from "react";
import { styled } from "../../core/styledComponents";
import develop from "../../shared/icons/develop.svg"
import design from "../../shared/icons/design.svg"
import managment from "../../shared/icons/managment.svg"
import analitic from "../../shared/icons/analitic.svg"
import tester from "../../shared/icons/tester.svg"
import network from "../../shared/icons/network.svg"
import { Card } from "./Card";
import { GlobalTitle, GlobalPAddingContainer } from "../../modules/ui/GlobalStyles";
import { theme } from "../../core/theme";
import { GlobalDescription } from "../../modules/ui/GlobalStyles"

export const Development = () => {
  return (
    <Wrapper id={"forWhom"}>
      <Container>
        <Title>
          Для кого
        </Title>
        <Description>
          В течение всего дня будут проходить доклады, мастер-классы, круглые столы, конкурсы с призами, а также будет работать ярмарка ИТ! Мы затронем все сферы ИТ - аналитику и проектирование, дизайн и разработку, тестирование и администрирование.
        </Description>
        <WrapperCards>
          <Card
            img={develop}
            alt={"develop"}
            description={"Разработка"}
          />
          <Card
            img={managment}
            alt={"managment"}
            description={"Менеджмент"}
          />
          <Card
            img={design}
            alt={"design"}
            description={"Дизайн"}
          />
          <Card
            img={analitic}
            alt={"analitic"}
            description={"Аналитика"}
          />
          <Card
            img={tester}
            alt={"tester"}
            description={"Тестирование"}
          />
          <Card
            img={network}
            alt={"network"}
            description={"Сети"}
          />
        </WrapperCards>
        <BottomDescription>
          Мы соберем все наше ИТ-сообщество, студентов и даже школьников!
        </BottomDescription>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${theme.colors.gray};
  justify-content: center;
  display: flex;
`

const Title = styled(GlobalTitle)`
  padding-bottom: 20px;
`

const Description = styled(GlobalDescription)`
  max-width: 1073px;
  padding-bottom: 40px;
`

const BottomDescription = styled(GlobalTitle)`
  font-size: 44px;
  max-width: 1070px;
  padding: 80px 0px 100px 0px;
  @media (max-width: 768px){
    font-size: 34px;
  }
  @media (max-width: 573px){
    font-size: 24px;
    padding: 30px 0 0 0;
  }
`

const Container = styled(GlobalPAddingContainer)`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 768px) {
    padding: 25px;
  }
`
const WrapperCards = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 50px;
  @media screen and (max-width: 1360px) {
    grid-template-columns: repeat(3, 1fr);        
  }
  @media screen and (max-width: 573px) {
    grid-template-columns: repeat(2, 1fr);       
    grid-gap: 0px; 
  }
`
