import { reducerWithInitialState } from "typescript-fsa-reducers";
import { UIAction } from "./action";

interface UIState {
  regButtonIsVisible: boolean;
}

const initialUIState: UIState = {
  regButtonIsVisible: false,
};

export const UIReducer = reducerWithInitialState(initialUIState).case(
  UIAction.setRegButtonVisibility,
  (state, payload) => ({ ...state, regButtonIsVisible: payload }),
);
