import React from "react"
import { styled } from "../core/styledComponents";
import fair from "../shared/icons/fair.svg"
import valli from "../shared/icons/valli.svg"
import { theme } from "../core/theme";
import { GlobalTitle, GlobalDescription, GlobalPAddingContainer } from "../modules/ui/GlobalStyles";
// import "../components/test.css"

export const Fair = () => {
    return (
        <Wrapper>
            <GlobalTitle>ИТ ярмарка</GlobalTitle>
            <Description>Большая зона для нетворкинга, роботы, лазеры, электро гитары, виртуальная реальность, конкурсы и розыгрыши призов, а также множество фото зон и зеленый уголок с огромным дисплеем для удобного просмотра всех залов.</Description>
            <Img src={fair} alt={"fair"} />
            {/* <ValliContainer className="cylon_eye">
                <img src={valli} alt={"valli"} />
            </ValliContainer> */}
        </Wrapper>
    )
}

const ValliContainer = styled.div`
  -webkit-animation: 4s linear 0s infinite alternate move_valli;
  animation: 1s linear 0s infinite alternate move_valli;
  @-webkit-keyframes move_valli {
  from {
    margin-left: 0%;
    /* transform: scale(1, -1); */
  }
  to {
    margin-left: 80%;
    /* transform: scale(1, -1); */
  }
}
@keyframes move_valli {
  from {
    margin-left: 0;
    /* transform: scale(-1, -1); */
  }
  to {
    margin-left: 100%;
    /* transform: scale(1, 1); */
    /* transform: rotate(180deg); */
  }
}
`

const Valli = styled.img`
    position: absolute;
    bottom: 0;
    right: 0;
`

const Wrapper = styled(GlobalPAddingContainer)`
    padding-left: 0px !important;
    padding-right: 0px !important;
    position: relative;
    @media screen and (max-width: 576px) {
        padding-top: 25px;
    }
`

const Description = styled(GlobalDescription)`
    max-width: 1102px;
    padding-bottom: 37px;
    padding-top: 20px;
`

const Img = styled.img`
    max-width: 100%;
    height: auto;
    @media screen and (max-width: 576px) {
        align-self: center;
        padding-top: 27px;
    }
`