import styled from "styled-components";
const Image = styled.img`
  position: absolute;
  transition: 0.3s ease-in-out;
  opacity: 0;
`;
export const Border = styled.div`
  border: 1px solid #b3c4fc;
  border-radius: 16px;
  height: 100%;
  width: 100%;
  padding: 16px 16px 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: 833px) {
    min-width: 134px;
    max-width: 221px;
  }
  @media screen and (max-width: 460px) {
    padding: 8px;
  }
  @media screen and (max-width: 375px) {
    max-width: 285px;
  }
  &:hover {
    box-shadow: 0 8px 25px #ebebec;
  }

  @media screen and (min-width: 1600px) {
    &:hover > .MOUSE {
      opacity: 1;
      top: 28px;
      left: 35px;
    }
    &:hover > .HEADPHONESRED {
      opacity: 1;
      top: 19px;
      left: 80px;
    }
    &:hover > .HEADPHONES {
      opacity: 1;
      top: 19px;
      left: 87px;
    }
    &:hover > .BADGE {
      opacity: 1;
      top: 167px;
      left: 92px;
    }
    &:hover > .MOBILE {
      opacity: 1;
      top: 233px;
      left: 111px;
    }
    &:hover > .GLASSES {
      opacity: 1;
      top: 4px;
      left: 100px;
    }
    &:hover > .GARNITURE {
      opacity: 1;
      top: 85px;
      left: 71px;
    }
    &:hover > .MICRO {
      opacity: 1;
      top: 122px;
      left: 21px;
    }
    &:hover > .HEADPHONESMICRO {
      opacity: 1;
      top: 97px;
      left: 111px;
    }
    &:hover > .CLASSMATEATTR {
      opacity: 1;
      top: 17px;
      left: 17px;
    }
  }
  @media screen and (max-width: 1600px) {
    &:hover > .MOUSE {
      opacity: 1;
      top: 32px;
      left: 27px;
    }
    &:hover > .HEADPHONESRED {
      opacity: 1;
      top: 19px;
      left: 66px;
    }
    &:hover > .HEADPHONES {
      opacity: 1;
      top: 16px;
      left: 67px;
    }
    &:hover > .BADGE {
      opacity: 1;
      top: 129px;
      left: 70px;
    }
    &:hover > .MOBILE {
      opacity: 1;
      top: 180px;
      left: 87px;
    }
    &:hover > .GLASSES {
      opacity: 1;
      top: 5px;
      left: 77px;
    }
    &:hover > .GARNITURE {
      opacity: 1;
      top: 68px;
      left: 58px;
    }
    &:hover > .MICRO {
      opacity: 1;
      top: 101px;
      left: 22px;
    }
    &:hover > .HEADPHONESMICRO {
      opacity: 1;
      top: 77px;
      left: 87px;
    }
    &:hover > .CLASSMATEATTR {
      opacity: 1;
      top: 17px;
      left: 17px;
    }
  }
  @media screen and (max-width: 1360px) {
    &:hover > .MOUSE {
      opacity: 1;
      top: 23px;
      left: 44px;
    }
    &:hover > .HEADPHONESRED {
      opacity: 1;
      top: 18px;
      left: 75px;
    }
    &:hover > .HEADPHONES {
      opacity: 1;
      top: 18px;
      left: 79px;
    }
    &:hover > .BADGE {
      opacity: 1;
      top: 107px;
      left: 82px;
    }
    &:hover > .MOBILE {
      opacity: 1;
      top: 149px;
      left: 94px;
    }
    &:hover > .GLASSES {
      opacity: 1;
      top: 8px;
      left: 85px;
    }
    &:hover > .GARNITURE {
      opacity: 1;
      top: 55px;
      left: 70px;
    }
    &:hover > .MICRO {
      opacity: 1;
      top: 86px;
      left: 47px;
    }
    &:hover > .HEADPHONESMICRO {
      opacity: 1;
      top: 64px;
      left: 93px;
    }
    &:hover > .CLASSMATEATTR {
      opacity: 1;
      top: 15px;
      left: 17px;
    }
  }
  @media screen and (max-width: 834px) {
    &:hover > .MOUSE {
      opacity: 1;
      left: 30px;
      top: 31px;
    }
    &:hover > .HEADPHONESRED {
      opacity: 1;
      top: 18px;
      left: 58px;
    }
    &:hover > .HEADPHONES {
      opacity: 1;
      top: 16px;
      left: 60px;
    }
    &:hover > .BADGE {
      opacity: 1;
      top: 106px;
      left: 61px;
    }
    &:hover > .MOBILE {
      opacity: 1;
      top: 149px;
      left: 73px;
    }
    &:hover > .GLASSES {
      opacity: 1;
      top: 9px;
      left: 66px;
    }
    &:hover > .GARNITURE {
      opacity: 1;
      top: 58px;
      left: 49px;
    }
    &:hover > .MICRO {
      opacity: 1;
      top: 87px;
      left: 22px;
    }
    &:hover > .HEADPHONESMICRO {
      opacity: 1;
      top: 64px;
      left: 71px;
    }
    &:hover > .CLASSMATEATTR {
      opacity: 1;
      top: 14px;
      left: 14px;
    }
  }
  @media screen and (max-width: 480px) {
    &:hover > .MOUSE {
      opacity: 1;
      top: 28px;
      left: 30px;
    }
    &:hover > .HEADPHONESRED {
      opacity: 1;
      top: 18px;
      left: 51px;
    }
    &:hover > .HEADPHONES {
      opacity: 1;
      top: 17px;
      left: 52px;
    }
    &:hover > .BADGE {
      opacity: 1;
      top: 98px;
      left: 54px;
    }
    &:hover > .MOBILE {
      opacity: 1;
      left: 68px;
      top: 135px;
    }
    &:hover > .GLASSES {
      opacity: 1;
      top: 10px;
      left: 61px;
    }
    &:hover > .GARNITURE {
      opacity: 1;
      left: 47px;
      top: 54px;
    }
    &:hover > .MICRO {
      opacity: 1;
      top: 74px;
      left: 20px;
    }
    &:hover > .HEADPHONESMICRO {
      opacity: 1;
      left: 69px;
      top: 59px;
    }
    &:hover > .CLASSMATEATTR {
      opacity: 1;
      top: 12px;
      left: 12px;
    }
  }
  @media screen and (max-width: 420px) {
    &:hover > .MOUSE {
      opacity: 1;
      left: 18px;
      top: 18px;
    }
    &:hover > .HEADPHONESRED {
      opacity: 1;
      top: 10px;
      left: 43px;
    }
    &:hover > .HEADPHONES {
      opacity: 1;
      top: 11px;
      left: 40px;
    }
    &:hover > .BADGE {
      opacity: 1;
      left: 47px;
      top: 88px;
    }
    &:hover > .MOBILE {
      opacity: 1;
      top: 120px;
      left: 57px;
    }
    &:hover > .GLASSES {
      opacity: 1;
      top: 0px;
      left: 47px;
    }
    &:hover > .GARNITURE {
      opacity: 1;
      top: 44px;
      left: 36px;
    }
    &:hover > .MICRO {
      opacity: 1;
      top: 69px;
      left: 6px;
    }
    &:hover > .HEADPHONESMICRO {
      opacity: 1;
      left: 57px;
      top: 50px;
    }
    &:hover > .CLASSMATEATTR {
      opacity: 1;
      top: 10px;
      left: 10px;
    }
  }
  @media screen and (max-width: 375px) {
    &:hover > .MOUSE {
      opacity: 1;
      top: 22px;
      left: 64px;
    }
    &:hover > .HEADPHONESRED {
      opacity: 1;
      top: 10px;
      left: 89px;
    }
    &:hover > .HEADPHONES {
      opacity: 1;
      top: 8px;
      left: 91px;
    }
    &:hover > .BADGE {
      opacity: 1;
      left: 92px;
      top: 99px;
    }
    &:hover > .MOBILE {
      opacity: 1;
      top: 141px;
      left: 105px;
    }
    &:hover > .GLASSES {
      opacity: 1;
      top: 2px;
      left: 94px;
    }
    &:hover > .GARNITURE {
      opacity: 1;
      top: 52px;
      left: 83px;
    }
    &:hover > .MICRO {
      opacity: 1;
      left: 55px;
      top: 76px;
    }
    &:hover > .HEADPHONESMICRO {
      opacity: 1;
      left: 104px;
      top: 56px;
    }
    &:hover > .CLASSMATEATTR {
      opacity: 1;
      top: 15px;
      left: 15px;
    }
  }
`;

export const ImageMouse = styled(Image)`
  top: 0px;
  left: 35px;
  @media screen and (max-width: 1600px) {
    left: 27px;
    width: 209px;
  }
  @media screen and (max-width: 1360px) {
    left: 44px;
    width: 176px;
  }
  @media screen and (max-width: 834px) {
    left: 30px;
    width: 164px;
  }
  @media screen and (max-width: 480px) {
    left: 30px;
    width: 155px;
  }
  @media screen and (max-width: 420px) {
    left: 18px;
    width: 147px;
  }
  @media screen and (max-width: 375px) {
    left: 64px;
    width: 162px;
  }
`;
export const ImageHeadPhoneRed = styled(Image)`
  top: 0px;
  left: 80px;
  width: 139px;
  @media screen and (max-width: 1600px) {
    top: -20px;
    left: 66px;
    width: 100px;
  }
  @media screen and (max-width: 1360px) {
    top: -20px;
    left: 75px;
    width: 84px;
  }
  @media screen and (max-width: 834px) {
    top: -20px;
    left: 58px;
    width: 78px;
  }
  @media screen and (max-width: 480px) {
    top: -20px;
    left: 51px;
    width: 78px;
  }
  @media screen and (max-width: 420px) {
    top: -10px;
    left: 43px;
    width: 71px;
  }
  @media screen and (max-width: 375px) {
    top: -10px;
    left: 89px;
    width: 78px;
  }
`;
export const ImageHeadPhones = styled(Image)`
  top: 0px;
  left: 87px;
  width: 172px;
  @media screen and (max-width: 1600px) {
    top: -25px;
    left: 67px;
    width: 137px;
  }
  @media screen and (max-width: 1360px) {
    top: -25px;
    left: 79px;
    width: 105px;
  }
  @media screen and (max-width: 834px) {
    top: -20px;
    left: 60px;
    width: 105px;
  }
  @media screen and (max-width: 480px) {
    top: -20px;
    left: 52px;
    width: 105px;
  }
  @media screen and (max-width: 420px) {
    top: -10px;
    left: 40px;
    width: 102px;
  }
  @media screen and (max-width: 375px) {
    top: -10px;
    left: 91px;
    width: 108px;
  }
`;
export const ImageBadge = styled(Image)`
  top: 0px;
  left: 92px;
  width: 118px;
  @media screen and (max-width: 1600px) {
    left: 70px;
    width: 92px;
  }
  @media screen and (max-width: 1360px) {
    left: 82px;
    width: 74px;
  }
  @media screen and (max-width: 834px) {
    left: 61px;
    width: 74px;
  }
  @media screen and (max-width: 480px) {
    left: 54px;
    width: 74px;
  }
  @media screen and (max-width: 420px) {
    left: 47px;
    width: 68px;
  }
  @media screen and (max-width: 375px) {
    left: 92px;
    width: 74px;
  }
`;
export const ImageMobile = styled(Image)`
  top: 0px;
  left: 111px;
  width: 66px;
  @media screen and (max-width: 1600px) {
    left: 87px;
    width: 47px;
  }
  @media screen and (max-width: 1360px) {
    left: 94px;
    width: 39px;
  }
  @media screen and (max-width: 834px) {
    left: 73px;
    width: 39px;
  }
  @media screen and (max-width: 480px) {
    width: 39px;
  }
  @media screen and (max-width: 420px) {
    left: 57px;
  }
  @media screen and (max-width: 375px) {
    top: 0px;
    left: 105px;
    width: 39px;
  }
`;
export const ImageGlasses = styled(Image)`
  top: -40px;
  left: 100px;
  width: 156px;
  @media screen and (max-width: 1600px) {
    top: -40px;
    left: 77px;
    width: 123px;
  }
  @media screen and (max-width: 1360px) {
    top: -20px;
    left: 85px;
    width: 96px;
  }
  @media screen and (max-width: 834px) {
    top: -20px;
    left: 66px;
    width: 96px;
  }
  @media screen and (max-width: 480px) {
    top: -20px;
    left: 61px;
    width: 96px;
  }
  @media screen and (max-width: 420px) {
    top: -10px;
    left: 47px;
  }
  @media screen and (max-width: 375px) {
    top: -10px;
    left: 94px;
    width: 96px;
  }
`;
export const ImageGarniture = styled(Image)`
  top: 0px;
  left: 71px;
  width: 134px;
  @media screen and (max-width: 1600px) {
    left: 58px;
    width: 98px;
  }
  @media screen and (max-width: 1360px) {
    left: 70px;
    width: 84px;
  }
  @media screen and (max-width: 834px) {
    left: 49px;
    width: 81px;
  }
  @media screen and (max-width: 480px) {
    width: 74px;
  }
  @media screen and (max-width: 420px) {
    left: 36px;
  }
  @media screen and (max-width: 375px) {
    left: 83px;
  }
`;
export const ImageMicro = styled(Image)`
  top: 0px;
  left: 21px;
  width: 154px;
  @media screen and (max-width: 1600px) {
    left: 22px;
    width: 108px;
  }
  @media screen and (max-width: 1360px) {
    left: 47px;
    width: 81px;
  }
  @media screen and (max-width: 834px) {
    left: 22px;
    width: 85px;
  }
  @media screen and (max-width: 480px) {
    left: 20px;
    width: 85px;
  }
  @media screen and (max-width: 420px) {
    left: 6px;
    width: 78px;
  }
  @media screen and (max-width: 375px) {
    left: 55px;
    width: 85px;
  }
`;
export const ImageHeadPhoneMicro = styled(Image)`
  top: 0px;
  left: 111px;
  width: 110px;
  @media screen and (max-width: 1600px) {
    left: 87px;
    width: 84px;
  }
  @media screen and (max-width: 1360px) {
    left: 93px;
    width: 70px;
  }
  @media screen and (max-width: 834px) {
    left: 71px;
    width: 70px;
  }
  @media screen and (max-width: 480px) {
    width: 60px;
  }
  @media screen and (max-width: 420px) {
    left: 57px;
  }
  @media screen and (max-width: 375px) {
    left: 104px;
    width: 69px;
  }
`;
export const ImageClassmates = styled(Image)`
  top: -40px;
  left: 17px;
  width: 272px;
  @media screen and (max-width: 1600px) {
    top: -40px;
    left: 17px;
    width: 218px;
  }
  @media screen and (max-width: 1360px) {
    top: -40px;
    left: 17px;
    width: 196px;
  }
  @media screen and (max-width: 834px) {
    top: -20px;
    left: 14px;
    width: 173px;
  }
  @media screen and (max-width: 480px) {
    top: -20px;
    left: 14px;
    width: 173px;
  }
  @media screen and (max-width: 420px) {
    top: -10px;
    left: 10px;
    width: 147px;
  }
  @media screen and (max-width: 375px) {
    top: -10px;
    left: 15px;
    width: 201px;
  }
`;
